<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';

export type ButtonType = 'primary' | 'secondary' | 'danger'

const props = withDefaults(defineProps<{
    disabled?: boolean,
    ghost?: boolean,
    label?: null | string,
    loading?: boolean,
    type?: ButtonType,
    href?: string | null,
    newTab?: boolean,
}>(), {
    type: 'primary',
    disabled: false,
    ghost: false,
    label: null,
    loading: false,
    href: null,
    newTab: false,
});
</script>

<template>
    <component
        :is="props.href ? 'a' : 'button'"
        class="relative shrink-0 inline-flex justify-center items-center border gap-2 select-none rounded-md px-4 py-2 text-sm font-medium leading-5 focus:outline-hidden cursor-pointer disabled:pointer-events-none disabled:opacity-50"
        style="border-style: inherit;"
        :disabled="disabled || loading"
        type="button"
        :href="props.href"
        :target="props.href && newTab ? '_blank' : null"
        :class="{
            'border-transparent text-white bg-blue-700 hover:bg-blue-800 shadow-xs': type === 'primary' && ! ghost,
            'border-gray-300 text-gray-700 bg-white hover:bg-gray-50 shadow-xs': type === 'secondary' && ! ghost,
            'border-transparent bg-red-600 text-white hover:bg-red-700 shadow-xs': type === 'danger' && ! ghost,
            'border-transparent text-blue-600 hover:text-blue-700 hover:bg-gray-100': type === 'primary' && ghost,
            'border-transparent text-gray-600 hover:text-gray-900 hover:bg-gray-100': type === 'secondary' && ghost,
            'border-transparent text-red-600 hover:text-red-700 hover:bg-red-100': type === 'danger' && ghost,
        }"
    >
        <span
            v-if="! loading && $slots.icon"
            class="size-5"
        >
          <slot name="icon" />
        </span>

        <svg v-if="loading" class="size-5 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>

        <span
            v-if="label !== null"
            v-text="label"
        />
    </component>
</template>
